import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Environment from '../views/Environment';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css'
import katex from 'katex';
import 'katex/dist/katex.min.css';
import './components.css';
window.katex = katex;


function ModifyPostModal(props) {

  const [formError, setFormError] = useState('');
  const [modifyPost, setModifyPost] = useState(
    {
      title: '',
      isImage: true,
      image: '',
      video: '',
      description: ''
    }
  );
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ]

  const module = {
      toolbar: toolbarOptions,
  };


  useEffect(() => {
    if(props.post !== null){
      setModifyPost(
        {
          title: props.post.title,
          idPost: props.post.idPost,
          isImage: props.post.video ? false : true,
          image: '',
          imgExtension: props.post.imgExtension ? props.post.imgExtension : '',
          video: props.post.video ? props.post.image : '',
          description: props.post.description
        }
      )
    }
  }, [props])

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setModifyPost((prev) => ({ ...prev, ...value }));
  }

   /**
   * onSubmit
   * @description Posts package product through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(modifyPost.isImage && !props.post.image && modifyPost.image.type !== "image/png" && modifyPost.image.type !== "image/jpg" && modifyPost.image.type !== "image/jpeg"){
        setFormError("La imagen adjuntada no es png, jpg o jpeg.");
        return;
      }

      const formData = new FormData();
      formData.append('title', modifyPost.title);
      formData.append('idPost', modifyPost.idPost);
      formData.append('isImage', modifyPost.isImage);
      formData.append('image', modifyPost.image);
      formData.append('video', modifyPost.video);
      formData.append('description', modifyPost.description);

      fetch(`${Environment()}/posts/modifyPost`, {
        method: 'POST',
        body: formData
      });
      
      setModifyPost(
        {
          title: '',
          isImage: '',
          image: '',
          video: '',
          description: ''
        }
      );
        
      window.location.reload();
  }

  const onFileChange = (e) => {
    if(e.target.files.length === 0) {
      updateForm({ image: ''});
    }
    else{
      updateForm({ image: e.target.files[0] })
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.title}
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-4">
              <Form.Label>Título</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="¿Qué es la agroecología?"
                onChange={(e) => updateForm({ title: e.target.value })}
                value={modifyPost.title}
              />
            </Form.Group>
            <Button  className="mb-3" variant='secondary' onClick={() => updateForm({ isImage: !modifyPost.isImage })}>
              { modifyPost.isImage &&
                <span>Adjuntar video</span>
              }
              { !modifyPost.isImage &&
                <span>Adjuntar imagen</span>
              }
            </Button>
            <Form.Group hidden={modifyPost.isImage} className="mb-3">
              <Form.Label>Video</Form.Label>
              <Form.Control
                required={!modifyPost.isImage}
                type="text"
                placeholder="https://youtu.be/NJ1CBZ34WyQ"
                onChange={(e) => updateForm({ video: e.target.value })}
                value={modifyPost.video}
              />
            </Form.Group>
            <Form.Group hidden={!modifyPost.isImage} className="mb-3">
              <Form.Label htmlFor="inputCategoryImage">Imagen</Form.Label>
              <Form.Control 
                required={modifyPost.isImage && !props.post.image}
                type='file'
                onChange={(e) => onFileChange(e)}
              />
              <Form.Text>Selecciona una imagen png, jpg o jpeg.</Form.Text>
            </Form.Group>
            { modifyPost.imgExtension && !modifyPost.image &&
            <Image 
              className='mb-3 mx-auto d-block'
              style={{ width: "calc(150px + 10vw)" }}
              src={`${Environment()}/post${modifyPost.idPost}${modifyPost.imgExtension}`}>
            </Image>
            }
            { modifyPost.image &&
              <Image 
                className='mb-3 mx-auto d-block'
                style={{ width: "calc(150px + 10vw)" }}
                src={URL.createObjectURL(modifyPost.image)}
                >
              </Image>
            }
            <p className='text-danger'>{formError}</p>
            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <ReactQuill maxLength={16777210} required modules={module} theme='snow' value={modifyPost.description} onChange={(content) => updateForm({ description: content })}/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModifyPostModal;