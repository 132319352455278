import React, { useState, useEffect } from 'react';
import NavbarUser from './../components/navbar';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import FooterUser from '../components/footerUser';
import Environment from './Environment';
import veggies from './../images/veggies.jpg';
import certificationLogo from './../images/LOGOS Y AFILIACIONES DE LA COOPERATIVA.png';
import NewPostModal from '../components/newPostModal';
import FooterHome from '../components/footerHome';


function AboutUs() {

    const [posts, setPosts] = useState([]);
    const [user, setUser] = useState({});
    const [modalShowNew, setModalShowNew] = useState(false);

    useEffect(() => {
        fetch(`${Environment()}/login/isLoggedIn`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(session => session.json())
        .then((session) => {
            setUser(session);
        })
    }, [])

    useEffect(() => {
        fetch(`${Environment()}/posts/getRecentPosts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setPosts(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [])

    const cardPosts = posts.map(post => {
        let video = '';

        if (post.video){
            video = post.video.split('/');
            if(video.length === 4){
                video = video[3].split('?')[0]
            } else {
                video  = '';
            }
        }

        return(
            <Col className='mb-5 mt-3' xs={8} sm={6} lg={4} key={post.idPost}>
                <Card>
                    { post.image !== null &&
                        <Card.Img src={`${Environment()}/post${post.idPost}${post.imgExtension}`}></Card.Img>
                    }
                    { post.video &&
                        <Card.Img src={`https://img.youtube.com/vi/${video}/hqdefault.jpg`} title="YouTube video player"></Card.Img>
                    }
                    <Card.Body>
                        <Card.Title>{post.title}</Card.Title>
                        <Button variant="secondary" href={`post/${post.idPost}`}>Saber más</Button>
                    </Card.Body>
                </Card>
            </Col>
        )
    })

    return (
        <div>
            <Row className='ps-5 pe-5 background-nav'>
                <NavbarUser active='/AboutUs'></NavbarUser>
            </Row>
            <Row className='ps-5 pe-5 grey-green-bg Layout d-none d-md-block'>
                <p className="fs-3 text-center"><b>Bienvenido a Terra Viva: Cultivando Vida, Sembrando Futuro</b></p>                        
                <Row className='mt-5'>
                    <Col xs lg="4" className='me-5'>
                        <Image style={{ width: "calc(200px + 500vw)" }} fluid='true' src={veggies} ></Image>
                    </Col>
                    <Col>    
                        <p className="justify">En Terra Viva, nuestra misión es proveer semillas orgánicas certificadas de alta calidad y productos botánicos que promuevan la biodiversidad y la sustentabilidad. Nos enorgullece ofrecer una variedad de semillas de hortalizas, leguminosas, plantas aromáticas y medicinales, así como árboles y propágulos como plántulas, esquejes, varetas y estacas. En este momento estamos en proceso de certificación.</p>
                        <p className="justify">Nuestro compromiso con la regeneración y el cuidado de la tierra guía cada paso de nuestro trabajo. Creemos en prácticas agrícolas que no sólo preserven, sino que restauren y fortalezcan los ecosistemas. Al optar por métodos sostenibles, fomentamos suelos fértiles, plantas saludables y una relación equilibrada con el medio ambiente.</p>
                        <p className="justify">Con cada semilla que producimos, llevamos un mensaje de esperanza y transformación. Nuestras semillas no sólo son el inicio de una cosecha; son el inicio de un cambio positivo. Al elegir productos de Terra Viva, estás contribuyendo al desarrollo de comunidades más conscientes, resilientes y conectadas con la naturaleza.</p>
                        <p className="justify">Imagina un jardín lleno de vida, donde cada planta crece de manera armoniosa y refleja el respeto hacia la tierra. Esto es lo que buscamos entregar con cada uno de nuestros productos. Nos apasiona proporcionar las herramientas necesarias para que productores, jardineros y entusiastas puedan participar en esta misión global de regenerar la vida.</p>
                        <p className="justify">Te invitamos a descubrir la diferencia que nuestras semillas y plantas pueden hacer. Forma parte de Terra Viva y siembra un futuro más saludable, próspero y en armonía con el planeta.</p>
                        <p>¡Juntos, transformemos la manera de producir y consumir, desde la raíz!</p>        
                        <p>Tu equipo en Terra Viva 🌱</p>
                    </Col>
                </Row>
                <p className="mt-5 fs-3 text-center"><b>¿Qué es la Certificación Orgánica Participativa?</b></p>                        
                <Row className='mt-5'>
                    <Col xs lg="4" className='me-5'>
                        <Image style={{ width: "calc(200px + 500vw)" }} fluid='true' src={certificationLogo} ></Image>
                    </Col>
                    <Col>    
                        <p className="justify">El Sistema de Certificación Orgánica Participativa (SCOP) es un sistema de certificación dirigido a pequeños productores o familias organizadas, que producen y comercializan sus productos directamente al consumidor final a través de tianguis o mercados establecidos.</p>
                        <p className="justify">El SENASICA, de acuerdo a lo establecido en la Ley de Productos Orgánicos en México (LPO), reconoce aquellos SCOP que tienen procedimientos bien definidos y documentados sobre el proceso de certificación participativa. En estos documentos debe ser posible constatar que los pequeños productores implementan prácticas de manejo, nutrición de cultivos, control de plagas y enfermedades, prácticas de cosecha, transporte, procesado, empacado o etiquetado, etc., atendiendo los principios y criterios de producción orgánica, que se encuentran en la LPO.</p>
                    </Col>
                </Row>
                
                {posts.length > 0 && <h3 className='mt-5 text-center'><b>Novedades</b></h3>}
                <Row>
                    {cardPosts}
                    {
                        user.isLoggedIn && (user.role === 'Coordinación' || user.role === 'Gerencia') &&
                        <Col className='mb-5 mt-3' xs={8} sm={6} lg={4}>
                            <Card className='h-100'>
                                <Button onClick={() => setModalShowNew(true)} className='align-self-center h-100 w-100' variant='light'><ion-icon size={'large'} name="add-circle-outline"/></Button>
                            </Card>
                        </Col>
                    }
                </Row>
            </Row>
            <Row className='d-md-none text-center justify-content-center mb-5'>
                <Row className='mt-5'>
                        <Image style={{ width: "calc(150px + 400vw)" }} fluid='true' src={veggies} ></Image>
                        <p className="fs-3 mt-2"><b>Bienvenido a Terra Viva: Cultivando Vida, Sembrando Futuro</b></p>
                        <p className="justify">En Terra Viva, nuestra misión es proveer semillas orgánicas certificadas de alta calidad y productos botánicos que promuevan la biodiversidad y la sustentabilidad. Nos enorgullece ofrecer una variedad de semillas de hortalizas, leguminosas, plantas aromáticas y medicinales, así como árboles y propágulos como plántulas, esquejes, varetas y estacas. En este momento estamos en proceso de certificación.</p>
                        <p className="justify">Nuestro compromiso con la regeneración y el cuidado de la tierra guía cada paso de nuestro trabajo. Creemos en prácticas agrícolas que no sólo preserven, sino que restauren y fortalezcan los ecosistemas. Al optar por métodos sostenibles, fomentamos suelos fértiles, plantas saludables y una relación equilibrada con el medio ambiente.</p>
                        <p className="justify">Con cada semilla que producimos, llevamos un mensaje de esperanza y transformación. Nuestras semillas no sólo son el inicio de una cosecha; son el inicio de un cambio positivo. Al elegir productos de Terra Viva, estás contribuyendo al desarrollo de comunidades más conscientes, resilientes y conectadas con la naturaleza.</p>
                        <p className="justify">Imagina un jardín lleno de vida, donde cada planta crece de manera armoniosa y refleja el respeto hacia la tierra. Esto es lo que buscamos entregar con cada uno de nuestros productos. Nos apasiona proporcionar las herramientas necesarias para que productores, jardineros y entusiastas puedan participar en esta misión global de regenerar la vida.</p>
                        <p className="justify">Te invitamos a descubrir la diferencia que nuestras semillas y plantas pueden hacer. Forma parte de Terra Viva y siembra un futuro más saludable, próspero y en armonía con el planeta.</p>
                        <p>¡Juntos, transformemos la manera de producir y consumir, desde la raíz!</p>        
                        <p>Tu equipo en Terra Viva 🌱</p>           
                </Row>
                <Row className='mb-20 mt-5'>
                    <h3 className='text-center'><b>¿Qué es la Certificación Orgánica Participativa?</b></h3>
                    <Image style={{ width: "calc(150px + 400vw)" }} fluid='true' src={certificationLogo} ></Image>
                    <p className="justify">El Sistema de Certificación Orgánica Participativa (SCOP) es un sistema de certificación dirigido a pequeños productores o familias organizadas, que producen y comercializan sus productos directamente al consumidor final a través de tianguis o mercados establecidos.</p>
                    <p className="justify mb-20">El SENASICA, de acuerdo a lo establecido en la Ley de Productos Orgánicos en México (LPO), reconoce aquellos SCOP que tienen procedimientos bien definidos y documentados sobre el proceso de certificación participativa. En estos documentos debe ser posible constatar que los pequeños productores implementan prácticas de manejo, nutrición de cultivos, control de plagas y enfermedades, prácticas de cosecha, transporte, procesado, empacado o etiquetado, etc., atendiendo los principios y criterios de producción orgánica, que se encuentran en la LPO.</p>
                </Row>
                {posts.length > 0 && <h3 className='text-center'><b>Novedades</b></h3>}
                <Row className=' mt-20 justify-content-center'>
                    {cardPosts}
                    {
                        user.isLoggedIn && (user.role === 'Coordinación' || user.role === 'Gerencia') &&
                        <Col className='mb-5 mt-3' xs={8} sm={6} lg={4}>
                            <Card className='h-100'>
                                <Button onClick={() => setModalShowNew(true)} className='align-self-center h-100 w-100' variant='light'><ion-icon size={'large'} name="add-circle-outline"/></Button>
                            </Card>
                        </Col>
                    }
                </Row>
                <h3 className='mt-20 text-center'><b>.</b></h3>
            </Row>
            <FooterHome></FooterHome>
            <div className='d-block d-lg-none home-footer home-blue-bg'></div>
            <FooterUser active='/AboutUs'></FooterUser>
            <NewPostModal
            show={modalShowNew}
            onHide={() => setModalShowNew(false)}
            title='Nuevo post'
            description=''
            />
        </div>
        
    );
}

export default AboutUs;
