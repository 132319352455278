import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill-new';
import Environment from '../views/Environment';
import 'react-quill-new/dist/quill.snow.css'
import './components.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';
window.katex = katex;

function NewPostModal(props) {

  const [formError, setFormError] = useState('');
  const [newPost, setNewPost] = useState(
    {
      title: '',
      isImage: true,
      image: '',
      video: '',
      description: ''
    }
  );
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ]

  const module = {
      toolbar: toolbarOptions,
  };

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setNewPost((prev) => ({ ...prev, ...value }));
  }

   /**
   * onSubmit
   * @description Posts package product through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(newPost.isImage && newPost.image.type !== "image/png" && newPost.image.type !== "image/jpg" && newPost.image.type !== "image/jpeg"){
        setFormError("La imagen adjuntada no es png, jpg o jpeg.");
        return;
      }

      const formData = new FormData();
      formData.append('title', newPost.title);
      formData.append('isImage', newPost.isImage);
      formData.append('image', newPost.image);
      formData.append('video', newPost.video);
      formData.append('description', newPost.description);

      fetch(`${Environment()}/posts/publishPost`, {
        method: 'POST',
        body: formData
      });
      
      setNewPost(
        {
          title: '',
          isImage: '',
          image: '',
          video: '',
          description: ''
        }
      );
        
      window.location.reload();
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.title}
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-4">
              <Form.Label>Título</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="¿Qué es la agroecología?"
                onChange={(e) => updateForm({ title: e.target.value })}
                value={newPost.title}
              />
            </Form.Group>
            <Button  className="mb-3" variant='secondary' onClick={() => updateForm({ isImage: !newPost.isImage })}>
              { newPost.isImage &&
                <span>Adjuntar video</span>
              }
              { !newPost.isImage &&
                <span>Adjuntar imagen</span>
              }
            </Button>
            <Form.Group hidden={newPost.isImage} className="mb-3">
              <Form.Label>Video</Form.Label>
              <Form.Control
                required={!newPost.isImage}
                type="text"
                placeholder="https://youtu.be/NJ1CBZ34WyQ"
                onChange={(e) => updateForm({ video: e.target.value })}
                value={newPost.video}
              />
            </Form.Group>
            <Form.Group hidden={!newPost.isImage} className="mb-3">
              <Form.Label htmlFor="inputCategoryImage">Imagen</Form.Label>
              <Form.Control 
                required={newPost.isImage}
                type='file'
                onChange={(e) => updateForm({ image: e.target.files[0] })}
              />
              <Form.Text>Selecciona una imagen png, jpg o jpeg.</Form.Text>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <ReactQuill maxLength={16777210} required modules={module} theme='snow' value={newPost.description} onChange={(content) => updateForm({ description: content })}/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewPostModal;