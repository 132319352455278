import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import NavbarUser from '../components/navbar';
import FooterUser from '../components/footerUser';
import Environment from './Environment';

function Cart() {
  const [products, setProducts] = useState({});
  const [order, setOrder] = useState({});
  const [deliveryDate, setDeliveryDate] = useState('');
  const [session, setSession] = useState({});
  const [error, setError] = useState('');
  const [lineError, setLineError] = useState('');
  const [errorProduct, setErrorProduct] = useState('');
  const [spinner, setSpinner] = useState(true);
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const [errorText, setErrorText] = useState('');
  const [UMs, setUMs] = useState([]);

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
    }, {});

    useEffect(() => {
      fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .then(data => data.json())
      .then((data) => {
          setUMs(arrayToObject(data, 'idUnit'));
      })
      .catch(err => {
          console.error(err);
      });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/login/isLoggedIn`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(sessionData => sessionData.json())
    .then((sessionData) => {
      setSession(sessionData);
      if(sessionData.isLoggedIn){
        fetch(`${Environment()}/orders/getCart/${sessionData.email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(orderData => orderData.json())
        .then((orderData) => {
          setOrder(orderData);
          fetch(`${Environment()}/orders/getCartProducts/${orderData.idOrder}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(productsData => productsData.json())
          .then((productsData) => {
            setProducts(productsData);
            if(productsData.length){
              fetch(`${Environment()}/suppliers/getDeliveryDay/${orderData.idOrder}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                }
              })
              .then(deliveryDate => deliveryDate.json())
              .then((deliveryDate) => {
                const deliveryDay = deliveryDate[0].deliveryDay;
                const today = new Date();
                const todayDay = today.getDay(); // Obtener el día de la semana actual (0: domingo, 1: lunes, ..., 6: sábado)
                
                // Calcular la diferencia entre el día actual y el día deseado
                let difference = deliveryDay - todayDay;
                
                // Si la diferencia es negativa, sumamos 7 para obtener el día de la semana siguiente
                if (difference <= 0) {
                  difference += 7;
                }
                
                // // Calcular la fecha del día de la semana deseado
                const result = today;
                result.setDate(today.getDate() + difference);
                setDeliveryDate(result);
              })
              .catch(err => {
                console.error(err);
              });
            }
          })
          .catch(err => {
            console.error(err);
          });
        })
        .catch(err => {
            console.error(err);
        });
      }
    })
  }, [])

  const total = Array.from(products).reduce((partialSum, product) => partialSum + (parseFloat(product.price))*parseFloat((product.quantity))*parseFloat((product.unitRelation)), 0);
  const isPriceRecalculatedCounter = Array.from(products).reduce((partialSum, product) => partialSum + (product.isPriceRecalculated ? 1 : 0), 0);
  const totalDiscount = Array.from(products).reduce((partialSum, product) => partialSum + parseFloat(product.discount)*parseFloat((product.quantity))*parseFloat((product.unitRelation)), 0);

  function enableInput(e){
    const formControl = document.getElementById(`formControl${e.currentTarget.id}`);
    const modify = document.getElementById(`modify${e.currentTarget.id}`);
    modify.hidden = false;
    formControl.firstChild.disabled = false;
    e.currentTarget.hidden = true;
  }

  function modifyProductQuantity(e, idProduct, seasonStart, seasonEnd, productName, singularName, pluralName){
    e.preventDefault();
    const quantity = document.getElementById(`formControl${idProduct}`).firstChild.value;

    fetch(`${Environment()}/orders/modifyOrderProduct`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idProduct: idProduct,
        idOrder: order.idOrder,
        quantity: quantity,
        seasonStart: seasonStart,
        seasonEnd: seasonEnd
      })
    })
    .then(response => response.json())
    .then((response) => {
      if(response.status === 200){
        window.location.reload();
      } else{
        setLineError('Solamente tenemos ' + Number(response.quantityAvailable) + ' ' + (Number(response.quantityAvailable) > 1 ? pluralName : singularName) + ' de ' + productName + '. Escribe una cantidad más pequeña.');
        setErrorProduct(idProduct);
      }
    })
    .catch(err => {
      console.error(err);
    });
  }

  function onChangeQuantity(e){
    const productIndex = products.findIndex(x => x.idProduct === parseInt(e.currentTarget.id));
    let copy = [...products];
    copy[productIndex].quantity = e.currentTarget.value;
    setProducts(copy);
  }

  function removeCartProduct(e){
    const idProduct = e.currentTarget.id;
    fetch(`${Environment()}/orders/removeCartProduct`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idProduct: idProduct,
        idOrder: order.idOrder,
      })
    })
    .then(() => {
      window.location.reload();
    })
    .catch(err => {
      console.error(err);
    });
  }

  function confirmOrder(e){
    e.preventDefault()
    setSpinner(false);
    if(!products.length){
      setError('Añade productos al carrito para realizar el pedido.');
      setSpinner(true);
    }
    else{
      fetch(`${Environment()}/orders/confirmOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idOrder: order.idOrder,
          deliveryDate: deliveryDate
        })
      })
      .then(response => response.json())
      .then((response) => {
        if(response.status === 200){
          const html = '<p><b>¡Hola ' + session.user + '!</b></p><p>Tu pedido de Terra Viva fue recibido exitosamente y será enviado el ' + deliveryDate.toLocaleDateString('es-MX', options) + '</p>' + document.getElementById('table').innerHTML + '<br/><p>Gracias por tu compra,</p><img width="75" src="cid:logoTerraViva.png"/>';
          const images = products.map(product => 'product' + product.idProduct + product.imgExtension);
          images.push('logoTerraViva.png');
          fetch(`${Environment()}/orders/genericEmail`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              receivers:[session.email],
              subject: 'Tu pedido de Terra Viva', 
              text: 'Tu pedido de Terra Viva fue recibido exitosamente y será enviado el ' + deliveryDate,
              html: html, 
              images: images
            })
          })
          .then(() => {
            window.location.href= '/misPedidos';
          })
          .catch(err => {
            console.error(err);
            setSpinner(true);
          });
        } else {
          setErrorText(Array.from(response.details).map((product, idx) => (
            <li hidden={product.quantity <= product.available ? true : false} key={product.idProduct}>{product.name}: {Number(product.available)} {Number(product.quantity) > 1 ? product.pluralName + " disponibles." : product.singularName + " disponible."}</li>
          )))
          setSpinner(true);
        }
      })
      .catch(err => {
        console.error(err);
        setSpinner(true);
        setError('En este momento no se puede realizar tu pedido. Inténtalo más tarde.');
      });
    }
  }

  const productRows = Array.from(products).map((product, idx) => (
    <tr key={product.idProduct} data-cy={"Product cart " + product.name}>
      <td width={100}>
        <Image id={`${product.idProduct}${product.imgExtension}`} src={`${Environment()}/image${product.idImage}${product.imgExtension}`} style={{ width: "calc(100px + 7vw)" }}></Image>
        <Form id={`${product.idProduct}`} name={product.idProduct}>
          <InputGroup id={`formControl${product.idProduct}`}>
            <Form.Control
              disabled
              data-cy={"quantity product " + product.name}
              id={product.idProduct}
              value={product.quantity}
              type="number"
              size="sm"
              min='0'
              step={product.isFloat === 1 ? '0.1' : '1'}
              onChange={(e) => {onChangeQuantity(e)}}
              />    
            {(product.quantity === 1.000 || product.quantity === '1.000') &&
              <InputGroup.Text>{product.singularName}</InputGroup.Text>
            }
            {product.quantity !== 1.000 && product.quantity !== '1.000' &&
              <InputGroup.Text>{product.pluralName}</InputGroup.Text>
            }
            <Button data-cy={"submit modify " + product.name} hidden id={`modify${product.idProduct}`} form={`modify${product.idProduct}`} onClick={(e) => {modifyProductQuantity(e, product.idProduct, product.seasonStart, product.seasonEnd,  product.name, product.singularName, product.pluralName)}} size='sm' variant="outline-success"><ion-icon size='small' name="checkmark-sharp"></ion-icon></Button>
            <Button data-cy={"modify quantity " + product.name} id={`${product.idProduct}`} size='sm' onClick={(e) => {enableInput(e)}} variant="outline-success"><ion-icon size='small' name="pencil-outline"></ion-icon></Button>
            <Button data-cy={"delete quantity " + product.name} size='sm' onClick={(e) => removeCartProduct(e)} id={product.idProduct} name={product.name} variant="outline-danger"><ion-icon name="trash-outline"></ion-icon></Button>
          </InputGroup>
        </Form>
      </td>
      <td className='text-center'>
        <p><b><small>{product.name} {product.isPriceRecalculated ? "*" : ''}</small></b></p>
        {
          product.discount > 0 &&
          <p><small className='me-3'>Precio unitario: <del className='text-muted'>${(parseFloat(product.discount)+parseFloat(product.price)).toFixed(2)}</del> ${product.price}/{product.singularName}</small></p>
        }
        {
          product.discount <= 0 &&
          <p><small className='me-3'>Precio unitario: ${product.price}/{UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}</small></p>
        }
        <p><small>Cantidad {product.isPriceRecalculated ? "(Aprox.)" : ''}: {(product.quantity*product.unitRelation).toFixed(2)} {UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}</small></p>
        <p><small>Subtotal {product.isPriceRecalculated ? "(Aprox.)" : ''}: ${(product.price*product.quantity*product.unitRelation).toFixed(2)}</small></p>
        <p className='text-danger' id={product.idProduct} hidden={errorProduct !== product.idProduct}><small>{lineError}</small></p>
      </td>
    </tr>                                          
  ));

  const productEmail = Array.from(products).map((product, idx) => (
    <tr style={{'border' :'1px solid black'}} key={product.idProduct}>
      <td>
        <Image width="75" id={`${product.idProduct}${product.imgExtension}`} src={`cid:product${product.idProduct}${product.imgExtension}`}></Image>
      </td>
      <td><b>{product.name} {product.isPriceRecalculated ? " *" : ''}</b></td>
      <td>
        ${product.price} x {Number(product.quantity) * Number(product.unitRelation)}
        {(Number(product.quantity) * Number(product.unitRelation) === 1 ) &&
          <span> {UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}</span>
        }
        {(Number(product.quantity) * Number(product.unitRelation) !== 1 ) &&
          <span>{UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].pluralName : ''}</span>
        }
        {product.isPriceRecalculated ? " (Aprox.)" : ''} = ${(product.price * product.quantity * product.unitRelation).toFixed(2)}
      </td>
    </tr>                     
  ));

  return (
    <div>
      <div className='ps-5 pe-5 background-nav'>
        <NavbarUser active='/carrito'></NavbarUser>
      </div>
      <Container className='Layout'>
        <Row className='bg-success rounded pt-2'>
          <Col>
            <h4><b><span className='text-white'><ion-icon name="cart"/> Mi carrito</span></b></h4>
          </Col>
          { session.isLoggedIn &&
            <Col>
              <p className='text-end text-white'>Total {isPriceRecalculatedCounter > 0 ? 'aprox.' : ''}: <b>${total.toFixed(2)} M.N.</b></p>
              { totalDiscount > 0 &&
                <p className='text-end text-white'>Ahorraste: <b>${totalDiscount.toFixed(2)} M.N.</b></p>
              }
            </Col>
          }
        </Row>
        { !session.isLoggedIn &&
          <div>
            <p><b>Inicia sesión para ver y añadir productos al carrito. </b></p>
            <Button href='/login' className='SecondaryBtn'>Iniciar sesión</Button>
          </div>
        }
        { session.isLoggedIn &&
            <div className='text-center'>
              <Table responsive>
                <tbody className='text-center'>
                  {productRows}
                </tbody>
              </Table>
              <small>{isPriceRecalculatedCounter > 0 ? '* Este producto se pesa antes de su entrega, lo cual puede provocar variaciones en el precio final.' : ''}</small>
              {deliveryDate &&
                  <p className='mt-3 green'>Pide hoy y recíbelo el: <b>{deliveryDate.toLocaleDateString('es-MX', options)}</b></p>
              }
              <Button hidden={!spinner} onClick={(e) => {confirmOrder(e)}} className='PrimaryBtn'>Realizar pedido</Button>
              <Button hidden={spinner} variant="success" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  />
              Cargando...
            </Button>
            </div>
          }
      <p className="text-danger text-center">{error}</p>
      <p className="text-danger" hidden={!errorText}>Lo sentimos. Los siguientes productos no se encuentran disponibles. Elige una cantidad menor o eliminalos del carrito:</p>
      <ul className="text-danger">
        {errorText}
      </ul>
      </Container>
      <Container id={'table'}>
        <table hidden className='mt-5' style={{'width':'100%'}}>
          <tbody>
            { productEmail.length > 0 &&
              productEmail
            }
            <tr style={{'border' :'1px solid black'}}>
              <td colSpan={10} className='text-end'>{isPriceRecalculatedCounter > 0 ? '* Este producto se pesa antes de su entrega, lo cual puede provocar variaciones en el precio final.' : ''}</td>
            </tr>
            <tr style={{'border' :'1px solid black'}}>
              <td colSpan={10} className='text-end'>Total {isPriceRecalculatedCounter > 0 ? 'aproximado' : ''}: <b>${total.toFixed(2)}</b></td>
            </tr>
          </tbody>
        </table>
      </Container>
      <FooterUser active='/carrito'></FooterUser>
    </div>
  );
}

export default Cart;
