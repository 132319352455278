import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import Button from "react-bootstrap/esm/Button";
import Pagination from 'react-bootstrap/Pagination';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import NavbarUser from "../components/navbar";
import CardProduct from './../components/cardProduct';
import Spinner from 'react-bootstrap/esm/Spinner';
import CardProductSmall from '../components/cardProductSmall';
import FooterUser from '../components/footerUser';
import FooterHome from '../components/footerHome';
import Environment from './Environment';
import './views.css';


function Products(){
    const cardsPerPageSmall = 4;
    const cardsPerPage = 3;
    const [products, setProducts] = useState([]);
    let { category } = useParams();
    const [filteredCategory, setFilteredCategory] = useState(category ? category : 'all');
    const [productsSearch, setProductsSearch] = useState('empty');
    const [search, setSearch] = useState('');
    const [message, setMessage] = useState(false);
    const [pages, setPages] = useState(1);
    const [active, setActive] = useState(1);

    const [orderProducts, setOrderProducts] = useState({});
    const [session, setSession] = useState({});
    const [soldInventory, setSoldInventory] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const [UMs, setUMs] = useState([]);

    const arrayToObject = (array, keyField) =>
      array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
      }, {});

    useEffect(() => {
      fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setUMs(arrayToObject(data, 'idUnit'));
      })
      .catch(err => {
          console.error(err);
      });
    }, [])

    useEffect(() => {
      fetch(`${Environment()}/supplierOrder/getSoldInventory`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {

        const temp = {};

        data.forEach(obj => {
          const id = obj.idProduct;
          temp[id] = obj;
        });

        setSoldInventory(temp);
      })
      .catch(err => {
          console.error(err);
      });
    }, [])

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    useEffect(() => {
        width >= 992 && setPages(Math.ceil(products.length/cardsPerPage));
        width < 992 && setPages(Math.ceil(products.length/cardsPerPageSmall));
    },[width, products]);

    let items = [];
    for (let page = 1; page <= pages; page++) {
        items.push(
            <Pagination.Item 
            onClick={() => {
                setActive(page); 
                window.scroll({
                    top: 50,
                    behavior: 'smooth'
                });
            }} key={page} active={page === active}>
                {page}
            </Pagination.Item>,
        );
    }

    const [categories, setCategories] = useState([]);
    useEffect(() => {
        fetch(`${Environment()}/categories/getCategories`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            }
        })
        .then(data => data.json())
        .then((data) => {
            setCategories(data);
        })
    }, []);

    useEffect(() => {
      if (filteredCategory === "all"){
        fetch(`${Environment()}/products/getVisibleProducts`, {
          method: 'GET',
          headers: {
          'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setPages(Math.ceil(data.length/cardsPerPage));
            setProducts(data);
            if(data.length === 0){
                setMessage('No se encontraron resultados.');
            }
            else{
                setMessage(false);
            }
        })
        .catch(err => {
            console.error(err);
        });
      } else {

        fetch(`${Environment()}/products/getProductsOfCategory/${filteredCategory? filteredCategory:category}`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            }
        })
        .then(data => data.json())
        .then((data) => {
            setPages(Math.ceil(data.length/cardsPerPage));
            setProducts(data);
            if(data.length === 0){
                setMessage('No se encontraron resultados.');
            }
            else{
                setMessage(false);
            }
        })
        .catch(err => {
            console.error(err);
        });
      }
    }, [filteredCategory, category])

    function FilterCategory(e){
        if (e.currentTarget.checked){
            setFilteredCategory(e.currentTarget.id);
            setProductsSearch('empty');
            setMessage(false);
            setSearch('');
        }
        else{
            e.currentTarget.checked = true;
        }
    }

    function searchProducts(e){
        const product = e.currentTarget.value;
        setSearch(product);
        if(!product){
            setProductsSearch('empty');
            setMessage(false);
        }
        else{
            setProductsSearch(products?.filter((row) => 
                                row?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")?.match(new RegExp(product.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), "i"))
            ));
        }
    }

    useEffect(() => {
      if(productsSearch.length === 0 || !productsSearch){
        setProductsSearch(false);
        setMessage('No se encontraron resultados.');
     }
     else{
         setMessage(false);
     }
    },[productsSearch]);

    useEffect(() => {
        fetch(`${Environment()}/login/isLoggedIn`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(sessionData => sessionData.json())
        .then((sessionData) => {
          setSession(sessionData);
          if(sessionData.isLoggedIn){
            fetch(`${Environment()}/orders/getCart/${sessionData.email}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              }
            })
            .then(orderData => orderData.json())
            .then((orderData) => {
              fetch(`${Environment()}/orders/getCartProducts/${orderData.idOrder}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                }
              })
              .then(productsData => productsData.json())
              .then((productsData) => {
                setOrderProducts(productsData);
                if(productsData.length){
                  fetch(`${Environment()}/suppliers/getDeliveryDay/${orderData.idOrder}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    }
                  })
                  .then(deliveryDate => deliveryDate.json())
                  .then((deliveryDate) => {
                    const deliveryDay = deliveryDate[0].deliveryDay;
                    const today = new Date();
                    const todayDay = today.getDay(); // Obtener el día de la semana actual (0: domingo, 1: lunes, ..., 6: sábado)
                    
                    // Calcular la diferencia entre el día actual y el día deseado
                    let difference = deliveryDay - todayDay;
                    
                    // Si la diferencia es negativa, sumamos 7 para obtener el día de la semana siguiente
                    if (difference <= 0) {
                      difference += 7;
                    }
                    
                    // // Calcular la fecha del día de la semana deseado
                    const result = today;
                    result.setDate(today.getDate() + difference);
                  })
                  .catch(err => {
                    console.error(err);
                  });
                }
              })
              .catch(err => {
                console.error(err);
              });
            })
            .catch(err => {
                console.error(err);
            });
          }
        })
    }, [orderProducts])
    
    const total = Array.from(orderProducts).reduce((partialSum, product) => partialSum + (parseFloat(product.price))*parseFloat((product.quantity))*parseFloat((product.unitRelation)), 0);
    const totalDiscount = Array.from(orderProducts).reduce((partialSum, product) => partialSum + parseFloat(product.discount)*parseFloat((product.quantity))*parseFloat((product.unitRelation)), 0);
    const isPriceRecalculatedCounter = Array.from(orderProducts).reduce((partialSum, product) => partialSum + (product.isPriceRecalculated ? 1 : 0), 0);

    const cards = Array.from((productsSearch === 'empty') ? products : (productsSearch === false) ? '' : productsSearch).slice((active-1)*cardsPerPage, active*cardsPerPage).map((product, idx) => (
        <Col key={product.idProduct}>
            <CardProduct
                id={product.idProduct}
                idImage={product.idImage}
                imgExtension={product.imgExtension}
                category={product.categoryName}
                name={product.name}
                price={product.price}
                singular={product.singularName}
                plural={product.pluralName}
                discount={product.discount}
                isFloat={product.isFloat}
                shortDescription={product.shortDescription}
                longDescription={product.longDescription}
                isSeason={product.isSeason}
                isInStock={(Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) > 0 ? true : false}
                availability={(Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) ? (Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) : 0}
                quantityInCart={orderProducts.quantity}
                finalUnit={UMs[product.idFinalUnit].singularName}
                hiddeBuyButton="false"
            />
        </Col>                      
    ));
    const cardsSmall = Array.from((productsSearch === 'empty') ? products : (productsSearch === false) ? '' : productsSearch).slice((active-1)*cardsPerPageSmall, active*cardsPerPageSmall).map((product, idx) => (
        <Col key={product.idProduct}>
            <CardProductSmall
                id={product.idProduct}
                idImage={product.idImage}
                imgExtension={product.imgExtension}
                category={product.categoryName}
                name={product.name}
                price={product.price}
                singular={product.singularName}
                plural={product.pluralName}
                discount={product.discount}
                isFloat={product.isFloat}
                shortDescription={product.shortDescription}
                longDescription={product.longDescription}
                isSeason={product.isSeason}
                isInStock={product.isInStock}
                finalUnit={UMs[product.idFinalUnit].singularName}
                hiddeBuyButton="false"
            />
        </Col>                      
    ));
    
    const filters = 
      categories.map((category) =>
        <Form.Check 
      onChange={(e) => {FilterCategory(e)}}
      checked={filteredCategory.toString() === category.idCategory.toString()? true: false}
      className='text-muted Checkbox'
      type='checkbox'
      key={category.idCategory}
      id={category.idCategory}
      label={category.name}
      />
    );

      const productRows = Array.from(orderProducts).map((product, idx) => (
        <tr key={product.idProduct} data-cy={"Product cart " + product.name}>
          <td width={100}>
            <Image id={`${product.idImage}${product.imgExtension}`} src={`${Environment()}/image${product.idImage}${product.imgExtension}`} style={{ width: "calc(100px + 3vw)" }}></Image>
            <InputGroup id={`formControl${product.idProduct}`}>
              <Form.Control
                disabled
                id={product.idProduct}
                value={product.quantity}
                type="number"
                size="sm"
                />    
              {(product.quantity === 1.000 || product.quantity === '1.000') &&
                <InputGroup.Text>{product.singularName}</InputGroup.Text>
              }
              {product.quantity !== 1.000 && product.quantity !== '1.000' &&
                <InputGroup.Text>{product.pluralName}</InputGroup.Text>
              }
            </InputGroup>
          </td>
          <td className='text-center'>
            <p><b><small>{product.name}</small></b></p>
            {
              product.discount > 0 &&
              <p><small><del className='text-muted'>${(parseFloat(product.discount)+parseFloat(product.price)).toFixed(2)}</del> ${product.price}/{(product.quantity === 1 || product.quantity === '1') ? product.singularName : product.pluralName}</small></p>
            }
            {
              product.discount <= 0 &&
              <p><small>${product.price}/{UMs[product.idFinalUnit].singularName}</small></p>
            }
            <p><small>Subtotal: ${(product.price*product.quantity*product.unitRelation).toFixed(2)}{product.isPriceRecalculated ? ' aprox.' :''}</small></p>
          </td>
        </tr>                                          
      ));

    const dropItem = categories.map((category) =>
      <Dropdown.Item key={category.idCategory} href={`/productos/${category.idCategory}`}><small>{category.name}</small></Dropdown.Item>
    );
    
    return(
        <div>
            <Row className='ps-5 pe-5 background-nav'>
                <NavbarUser active='/productos'></NavbarUser>
            </Row>
            <Row className='ps-5 pe-5 grey-green-bg'>
                <div className='Layout'> 
                    <Row>
                        <Col className='border rounded light-green-bg d-none d-xl-block d-lg-block' lg={2}>
                            <Row className='text-muted mt-2'>
                              <h5 className='green'><b>Categorías</b></h5>
                            </Row>
                            <Form>
                              <Form.Check 
                                onChange={(e) => {FilterCategory(e)}}
                                checked={filteredCategory.toString() === "all" ? true: false}
                                className='text-muted Checkbox'
                                type='checkbox'
                                key="all"
                                id="all"
                                label="Todas"
                              />
                              {filters}
                            </Form>
                        </Col>
                        <Col className='d-none d-lg-block'>
                            <h3 className='mb-4'><b>Productos <span className='green'>agroecológicos</span></b></h3>
                            <InputGroup>
                                <Form.Control
                                type="search"
                                value={search}
                                placeholder={filteredCategory === 'all' ? "Buscar en todas las categorías" : (category ? 'Buscar en la categoría' : 'Buscar en ' + categories[filteredCategory-1].name)}
                                onChange={(e) => {searchProducts(e)}}
                                />
                                <Button size='sm' className='PrimaryBtn'><ion-icon name="search"></ion-icon></Button>
                            </InputGroup>
                            <Container hidden={message? false : true} className="mt-3">
                                <Alert variant={'info'}>{message}</Alert>
                            </Container>
                            { (cards.length === 0) && !message &&
                                <Container className='Layout text-center'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </Spinner>
                                </Container>
                            }
                            { (cards.length > 0) &&
                                <Row md={3} lg={3} xl={3} className="mt-3 g-4">
                                    {cards}
                                </Row>
                            }
                            <Row className="mt-5">
                                <Pagination className='justify-content-center'>{items}</Pagination>
                            </Row>
                        </Col>
                        <Col className='d-block d-lg-none'>
                            <h3 className='mb-4'><b>Productos <span className='green'>agroecológicos</span></b></h3>
                            <InputGroup>
                                <Form.Control
                                type="search"
                                placeholder="Buscar productos"
                                onChange={(e) => {searchProducts(e)}}
                                />
                                <Button size='sm' className='PrimaryBtn'><ion-icon name="search"></ion-icon></Button>
                            </InputGroup>
                            <Dropdown className=' mt-3' title="Categorías">
                              <Dropdown.Toggle variant="success" id="filteredCategory">
                                {categories.length > 0 && filteredCategory !== 'all' && categories[filteredCategory-1] ? categories[filteredCategory-1].name : 'Todas las categorías'}
                              </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item key="all" href={`/productos`}><small>Todas las categorías</small></Dropdown.Item>
                                    {dropItem}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Row xs={1} sm={1} className="mt-3 g-4">
                                {cardsSmall}
                            </Row>
                            <Row>
                                <Pagination className='justify-content-center'>{items}</Pagination>
                            </Row>
                        </Col>
                        <Col hidden={orderProducts.length === 0 ? true : false} xl={3} className='d-none d-xl-block'>
                            <Container className='border rounded bg-white'>
                                <Row className='bg-success rounded pt-2'>
                                    <a href='/carrito'>
                                        <h4><b><span className='text-white'><ion-icon name="cart"/> Mi carrito</span></b></h4>
                                    </a>
                                    <div className='mt-2' hidden={!session.isLoggedIn}>
                                      <p className='text-white'>Total {isPriceRecalculatedCounter > 0 ? 'aprox.' : ''}: <b>${total.toFixed(2)} M.N.</b></p>
                                      { totalDiscount > 0 &&
                                          <p className='text-white'>Ahorraste: <b>${totalDiscount.toFixed(2)} M.N.</b></p>
                                      }
                                    </div>
                                </Row>
                                <div hidden={session.isLoggedIn}>
                                    <p><b>Inicia sesión para ver y añadir productos al carrito. </b></p>
                                    <Button href='/login' className='SecondaryBtn'>Iniciar sesión</Button>
                                </div>
                                { session.isLoggedIn &&
                                    <div className='text-center'>
                                        <Table responsive>
                                            <tbody className='text-center'>
                                            {productRows}
                                            </tbody>
                                        </Table>
                                        <Button className='mb-3' href='/carrito' variant="success">Ir al carrito</Button>
                                    </div>
                                }
                            </Container>
                        </Col>
                    </Row>
                </div>
            </Row>
            <FooterHome></FooterHome>
            <div className='d-block d-lg-none home-footer home-blue-bg'></div>
            <FooterUser active='/productos'></FooterUser>
        </div>
    );
}

export default Products;