import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ReactQuill from 'react-quill-new';
import Environment from '../views/Environment';
import './components.css';
import 'react-quill-new/dist/quill.snow.css'
import './components.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';
window.katex = katex;

function NewProductModal(props) {

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [images, setImages] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [UMs, setUMs] = useState([]);
  const [UMObject, setUMObject] = useState({});
  const [pages, setPages] = useState([]);
  const [active, setActive] = useState(1);
  const cardsPerPage = 4;
  const [formError, setFormError] = useState('');
  const [newProduct, setNewProduct] = useState(
    {
      name: '',
      shortDescription: '',
      longDescription: '',
      image: '',
      idSupplier: '',
      UM: '',
      idFinalUnit: '',
      isPriceRecalculated: '',
      unitRelation: '',
      brutePrice: '',
      price: '',
      discount: '',
      cost: '',
      seasonStartDay: '',
      seasonStartMonth: '',
      seasonEndDay: '',
      seasonEndMonth: '',
    }
  );
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ]

  const module = {
      toolbar: toolbarOptions,
  };

  const addImage = (newItem) => {
    const itemExists = productImages.some(item => item.idImage === newItem.idImage);
    
    if (!itemExists && ((productImages[0] && productImages[0].isVideo === 0) || newItem.isVideo === 0)) {
      setProductImages(prevItems => [...prevItems, newItem]);
    }
  };

  const removeImage = (idImage) => {
    setProductImages(prevItems => prevItems.filter(item => item.idImage !== idImage));
  };

  useEffect(() => {
    fetch(`${Environment()}/images/getPages/false`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      if(data[0].results > 0)
        setPages(Math.ceil(data[0].results / cardsPerPage)); 
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/images/getImages/${cardsPerPage}/${active}/false`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setImages(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [cardsPerPage, active])

  function convertVideoURL(string){
    let video = '';
    video = string.split('/');
      if(video.length === 4){
          video = video[3].split('?')[0]
      } else {
          video  = '';
      }

    return video;
  }

  const imageRows = Array.from(images).map((image, idx) => (
    <Col key={idx}>
      <Card>
        <Card.Header>
          <Row>
            <Button 
                className='PrimaryBtn'
                data-cy={idx === 0 ? "image product" : ''}
                onClick={() => addImage({ idImage: image.idImage, imgExtension: image.imgExtension, isVideo: image.isVideo, video: image.video })}
              >
                <ion-icon 
                name="add" 
                />
              </Button>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Img hidden={image.isVideo} className='mb-5' src={`${Environment()}/image${image.idImage}${image.imgExtension}`}/>  
          <iframe hidden={!image.isVideo} crossOrigin="anonymous" className='mb-5 card-img-top' src={`https://www.youtube-nocookie.com/embed/${convertVideoURL(image.video)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>              
          <Card.Text className='text-center'><b><small>{image.name}</small></b></Card.Text>
          <Card.Text className='text-center'><i><small>{image.scientificName}</small></i></Card.Text>
        </Card.Body>
      </Card>
    </Col>
  ));

  const addedImagesRows = Array.from(productImages).map((image, idx) => (
    <Col key={idx}>
      <Card>
        <Card.Header>
          <Row>
            <Button 
                variant='grey'
                onClick={() => removeImage(image.idImage)}
              >
                <ion-icon
                name="trash"
                />
              </Button>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Img hidden={image.isVideo} className='mb-5' src={`${Environment()}/image${image.idImage}${image.imgExtension}`}/>
          <iframe hidden={!image.isVideo} crossOrigin="anonymous" className='mb-5 card-img-top' src={`https://www.youtube-nocookie.com/embed/${convertVideoURL(image.video)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                
        </Card.Body>
      </Card>
    </Col>
  ));

  //Disable scroll on number inputs
  useEffect(() => {
    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
          document.activeElement.blur();
      }
    });
  }, [])

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {});

  useEffect(() => {
    fetch(`${Environment()}/categories/getCategories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
        setCategories(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/suppliers/getSuppliers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setSuppliers(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setUMs(data);
      setUMObject(arrayToObject(data, 'idUnit'));
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setNewProduct((prev) => ({ ...prev, ...value }));
  }

   /**
   * onSubmit
   * @description Posts category through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(!newProduct.UM || selectedCategories.length < 1 || !newProduct.idSupplier || productImages.length < 1){
        setFormError("Llena todos los campos y adjunta por lo menos una imagen.")
        return;
      }

      const formData = new FormData();
      formData.append('images', JSON.stringify(productImages));
      formData.append('name', newProduct.name);
      formData.append('shortDescription', newProduct.shortDescription);
      formData.append('longDescription', newProduct.longDescription);
      formData.append('categories', selectedCategories);
      formData.append('idSupplier', newProduct.idSupplier);
      formData.append('UM', newProduct.UM);
      formData.append('idFinalUnit', newProduct.idFinalUnit);
      formData.append('isPriceRecalculated', newProduct.isPriceRecalculated);
      formData.append('unitRelation', newProduct.unitRelation);
      formData.append('price', newProduct.price);
      formData.append('discount', newProduct.discount);
      formData.append('cost', newProduct.cost);
      formData.append('seasonStart', newProduct.seasonStartDay && newProduct.seasonStartMonth ? (newProduct.seasonStartDay + "," + newProduct.seasonStartMonth) : null);
      formData.append('seasonEnd', newProduct.seasonEndDay && newProduct.seasonEndMonth ? (newProduct.seasonEndDay + "," + newProduct.seasonEndMonth) : null);

      fetch(`${Environment()}/products/getProduct/${newProduct.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length){
          setFormError("El nombre del producto ya existe");
          return;
        }
        else{
          fetch(`${Environment()}/products/postProduct`, {
            method: 'POST',
            body: formData
          });
          
          setNewProduct(
            {
              name: '',
              shortDescription: '',
              longDescription: '',
              image: '',
              idCategory: '',
              idSupplier: '',
              UM: '',
              idFinalUnit: '',
              isPriceRecalculated: '',
              unitRelation: '',
              price: '',
              discount: '',
              cost: '',
              seasonStartDay: '',
              seasonStartMonth: '',
              seasonEndDay: '',
              seasonEndMonth: ''
            }
          );
            
          window.location.reload();
        }
      })
  }

  const months = [
    {number:1, name:'enero'}, 
    {number:2, name:'febrero'},
    {number:3, name:'marzo'},
    {number:4, name:'abril'},
    {number:5, name:'mayo'},
    {number:6, name:'junio'},
    {number:7, name:'julio'},
    {number:8, name:'agosto'},
    {number:9, name:'septiembre'},
    {number:10, name:'octubre'},
    {number:11, name:'noviembre'},
    {number:12, name:'diciembre'}
  ];

  function handleCategories(e, idCategory){
    const value = e.target.checked;
    const currentVals = selectedCategories;
    const index = currentVals.indexOf(idCategory);

    if(value){
      currentVals.push(idCategory);
    } else if (index > -1){
      currentVals.splice(index)
    }

    setSelectedCategories(currentVals);
  }

  const selectMonths = Array.from(months).map((month, idx) => (
  <option key={month.number} value={month.number}>{month.name}</option>
  ));

  const categorySelect = Array.from(categories).map((category, idx) => (
    <Form.Check
      onClick={(e) => handleCategories(e, category.idCategory)}
      key={category.idCategory}
      value={category.idCategory}
      type='checkbox'
      id={category.idCategory}
      label={category.name}
    />                   
  ));

  const supplierSelect = Array.from(suppliers).map((supplier, idx) => (
    <option key={supplier.idSupplier} value={supplier.idSupplier}>{supplier.name}</option>                    
  ));

  const UMSelect = Array.from(UMs).map((UM, idx) => (
    <option key={UM.idUnit} value={UM.idUnit}>{UM.singularName}</option>                    
  ));

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>{props.title}</b>
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Nombre *</b></Form.Label>
              <Form.Control
                required
                type="text"
                data-cy="product name"
                placeholder="Manzana roja"
                onChange={(e) => updateForm({ name: e.target.value })}
                value={newProduct.name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Descripción corta *</b></Form.Label>
              <Form.Control
                required
                type="text"
                data-cy="product description"
                placeholder="Manzana Royal Gala"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                value={newProduct.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductLongDesc"><b>Descripción larga</b></Form.Label>
              <ReactQuill data-cy="product long description" maxLength={16777210} required modules={module} theme='snow' value={newProduct.longDescription} onChange={(content) => updateForm({ longDescription: content })}/>
              
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Categorías *</b></Form.Label>
                {categorySelect}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Proveedor *</b></Form.Label>
              <Form.Select
                required
                data-cy="product supplier"
                onChange={(e) => updateForm({ idSupplier: e.target.value })}
                value={newProduct.idSupplier}
              >
                <option value=''>Selecciona un proveedor</option>  
                {supplierSelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Unidad de medida pedido *</b></Form.Label>
              <Form.Select
                required
                type="text"
                data-cy="product UM"
                placeholder="KG"
                onChange={(e) => updateForm({ UM: e.target.value })}
                value={newProduct.UM}
              >
                <option value=''>Selecciona una unidad de medida</option>
                {UMSelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Unidad de medida venta *</b></Form.Label>
              <Form.Select
                required
                type="text"
                data-cy="product final unit"
                placeholder="KG"
                onChange={(e) => updateForm({ idFinalUnit: e.target.value })}
                value={newProduct.idFinalUnit}
              >
                <option value=''>Selecciona una unidad de medida</option>
                {UMSelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>¿El producto se tiene que pesar o medir antes de la entrega? *</b></Form.Label>
              <Form.Select
                required
                type="text"
                data-cy="product isPriceRecalculated"
                placeholder="KG"
                onChange={(e) => {updateForm({ isPriceRecalculated : e.target.value }); updateForm({ unitRelation: e.target.value === 'false' ? 1 : newProduct.unitRelation })}}
                value={newProduct.isPriceRecalculated}
              >
                <option value=''>Selecciona una opción</option>
                <option value={true}>Si</option>
                <option value={false}>No</option>
              </Form.Select>
            </Form.Group>
            <Form.Label className='green'><b>Escribe la relación que tienen apróximadamente ambas unidades de medida *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>1 {UMObject[newProduct.UM] ? UMObject[newProduct.UM].singularName : '' } es igual a </InputGroup.Text>
              <Form.Control
                required
                data-cy="product UM relation"
                disabled={newProduct.isPriceRecalculated === 'false'}
                type="number"
                placeholder="30.99"
                step={ UMObject[newProduct.idFinalUnit] ? UMObject[newProduct.idFinalUnit].isFloat === 1 ? '0.001' : '1' : ''}
                min={UMObject[newProduct.idFinalUnit] ? UMObject[newProduct.idFinalUnit].isFloat === 1 ? '0.001' : '1' : ''}
                onChange={(e) => {updateForm({ unitRelation: e.target.value})}}
                value={newProduct.unitRelation}
              />
              <InputGroup.Text>{UMObject[newProduct.idFinalUnit]? UMObject[newProduct.idFinalUnit].singularName : ''}</InputGroup.Text>
            </InputGroup>
            <Form.Label className='green'><b>Costo (costo de producción o pago al productor) *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                data-cy="product cost"
                placeholder="12"
                min={0}
                onChange={(e) => updateForm({ cost: e.target.value })}
                value={newProduct.cost}
              />
            </InputGroup>
            <Form.Label className='green'><b>Precio (precio a los clientes de Terra Viva) *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                data-cy="product price"
                placeholder="30.99"
                min={newProduct.cost}
                onChange={(e) => {updateForm({ brutePrice: e.target.value}); updateForm({ price: e.target.value - newProduct.discount});}}
                value={newProduct.brutePrice}
              />
            </InputGroup>
            <Form.Label className='green'><b>Descuento *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                data-cy="product discount"
                placeholder="0.99"
                min={0}
                max={newProduct.brutePrice}
                onChange={(e) => { updateForm({ discount: e.target.value }); updateForm({ price: newProduct.brutePrice - e.target.value});}}
                value={newProduct.discount}
              />
            </InputGroup>
            <Form.Label className='green'><b>Precio con descuento</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                disabled
                type="number"
                placeholder="30"
                min={0}
                value={newProduct.price}
              />
            </InputGroup>
            <Form.Label className='green'><b>Inicio de temporada</b></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                data-cy="product seasonStartDay"
                value={newProduct.seasonStartDay}
                min={1}
                max={31}
                onChange={(e) => updateForm({ seasonStartDay: e.target.value })}
                placeholder='Escribe un día'
                >
              </Form.Control>
              <Form.Select
                type="text"
                data-cy="product seasonStartMonth"
                value={newProduct.seasonStartMonth}
                onChange={(e) => updateForm({ seasonStartMonth: e.target.value })}
                >
                <option value=''>Selecciona un mes</option>
                {selectMonths}
              </Form.Select>
            </InputGroup>
            <Form.Label className='green'><b>Fin de temporada</b></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                data-cy="product seasonEndDay"
                value={newProduct.seasonEndDay}
                min={1}
                max={31}
                placeholder='Escribe un día'
                onChange={(e) => updateForm({ seasonEndDay: e.target.value })}
              >
              </Form.Control>
              <Form.Select
                type="text"
                data-cy="product seasonEndMonth"
                value={newProduct.seasonEndMonth}
                onChange={(e) => updateForm({ seasonEndMonth: e.target.value })}
              >
                <option value=''>Selecciona un mes</option>
                {selectMonths}
              </Form.Select>
            </InputGroup>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Imágenes *</b></Form.Label>
              <p>Inserta una imagen antes de insertar videos, ya que la primera imagen se utilizará de portada.</p>
              <Container className='mt-3'>
              <Row md={4} lg={4} xl={4}>
                {addedImagesRows}
              </Row>
              </Container>
              <Container className='mt-3'>
                <Row>
                  <Col>
                    <ion-icon onClick={ e => {setActive(active <= 1 ? 1 : active - 1)}} name="arrow-back-outline"></ion-icon>
                  </Col>
                  <Col className='text-center'>
                    <p><b>Galería</b></p>
                  </Col>
                  <Col className='text-end'>
                    <ion-icon onClick={ e => {setActive(active >= pages ? pages : active + 1)}} name="arrow-forward-outline"></ion-icon>
                  </Col>
                </Row>
                <Row md={4} lg={4} xl={4}>
                  {imageRows}
                </Row>
              </Container>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button data-cy="submit product" type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewProductModal;