import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/esm/Table";
import Button from "react-bootstrap/esm/Button";
import Environment from '../views/Environment';
import BlockUserModal from './blockUserModal';
import ModifyUserRole from './modifyUserRole';
import { Navigate } from "react-router-dom";
import './components.css';

function UserTable(){
    const [users, setUsers] = useState([]);
    const [modalShowBlock, setModalShowBlock] = useState(false);
    const [modifyUserRole, setModifyUserRole] = useState(false);
    const [modifyUser, setModifyUser] = useState(false);
    const [blockUnblock, setBlockUnblock] = useState([]);
    const [roleValues, setRoleValues] = useState([]);
    const [session, setSession] = useState({});

    function Block(e){
        setModalShowBlock(true);
        setBlockUnblock({
            user: e.currentTarget.id,
            action: e.currentTarget.name
        });
    }

    function ModifyRole(e){
        setModifyUserRole(true);
        setRoleValues({
            email:e.currentTarget.id,
            role:e.currentTarget.name
        });
    }

    useEffect(() => {
        fetch(`${Environment()}/login/getUsers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
          setUsers(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [])

    function HandleUserChange(e, user){
        e.preventDefault();
        setModifyUser(user);
    }

    useEffect(() => {
        fetch(`${Environment()}/login/isLoggedIn`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(session => session.json())
        .then((session) => {
            setSession(session);
        })
    }, [])

    const userRows = Array.from(users).map((user, idx) => (
        <tr key={user.email}>
          <td hidden={user.role === 'SignOnLess' || session.email === user.email}><a href='/modificarusuario' onClick={ e => HandleUserChange(e, user.email)}>{user.firstName}</a></td>
          <td hidden={session.email !== user.email}><a href='/micuenta'>{user.firstName}</a></td>
          <td hidden={user.role !== 'SignOnLess' || session.email === user.email}> <a href='/modificarusuario' onClick={ e => HandleUserChange(e, user.email)}>{user.firstName}</a>{modifyUser? user.email : ''}</td>
          {modifyUser === user.email && <Navigate to="/modificarusuario" state={{email:user.email}}/>}
          <td>{user.lastName}</td>
          <td>{user.email}</td>
          <td>{user.phone}</td>
          <td>{user.street && `${user.street} ${user.externalNumber} ${user.internalNumber}, ${user.neighborhood}, ${user.postalCode}`}</td>
          <td>{user.role}</td>
          <td className='text-center'><Button id={user.email} name={user.roleID} className='SecondaryBtn' onClick={(e) => ModifyRole(e)}><ion-icon name="pencil-sharp"></ion-icon></Button></td>
          <td hidden={user.isBlocked} className='text-center'><Button id={user.email} name='BLOQUEAR' onClick={(e) => {Block(e)}} variant="outline-danger"><ion-icon name="lock-closed"></ion-icon></Button></td>
          <td hidden={!user.isBlocked} className='text-center'><Button id={user.email} name='DESBLOQUEAR' onClick={(e) => {Block(e)}} variant="outline-danger"><ion-icon name="lock-open"></ion-icon></Button></td>
        </tr>                     
    ));

    return(
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Correo electrónico / ID</th>
                        <th>Teléfono</th>
                        <th>Dirección</th>
                        <th>Rol</th>
                        <th>Modificar rol</th>
                        <th>Bloquear/desbloquear cuenta</th>
                    </tr>
                </thead>
                <tbody>
                    {userRows}
                </tbody>
            </Table>
            <BlockUserModal
                show={modalShowBlock}
                onHide={() => setModalShowBlock(false)}
                user={blockUnblock.user}
                action={blockUnblock.action}
            />
            <ModifyUserRole
                show={modifyUserRole}
                onHide={() => setModifyUserRole(false)}
                email={roleValues.email}
                role={roleValues.role}
            />
        </Container>
    );
}

export default UserTable;