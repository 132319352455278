import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Environment from '../views/Environment';
import './components.css';

function SupplierProductsModal(props) {

  const [products, setProducts] = useState([]);

  useEffect(() => {
    if(props.idsupplier){
      fetch(`${Environment()}/products/getSupplierProducts/${props.idsupplier}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setProducts(data)
      })
      .catch(err => {
          console.error(err);
      });
    }
    
  }, [props.idsupplier])
    
  const productTable = Array.from(products).map((product, idx) => (
    <tr key={product.idProduct}>
      <td>{product.name}</td>
      <td>{product.categoryName}</td>
      <td>{product.singularName}</td>
      <td>${product.price}</td>
      <td>${product.cost}</td>
      <td>{product.supplierName}</td>
      <td>{product.shortDescription}</td> 
    </tr>                           
  ));

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Productos de <b>{props.name}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table>
                <thead>
                    <tr>
                    <th>Nombre</th>
                        <th>Categoría</th>
                        <th>Unidad de medida</th>
                        <th>Precio</th>
                        <th>Costo</th>
                        <th>Proveedor</th>
                        <th>Descripción corta</th>
                    </tr>
                </thead>
                <tbody>
                    {productTable}
                </tbody>
            </Table>
            {productTable.length < 1 && 
              <p>No hay productos que mostrar.</p>
            }
        </Modal.Body>
    </Modal>
  );
}

export default SupplierProductsModal;