import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Environment from '../views/Environment';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css'
import katex from 'katex';
import 'katex/dist/katex.min.css';
import './components.css';
window.katex = katex;

function ModifyProductModal(props) {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categorySelect, setCategorySelect] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [images, setImages] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [UMs, setUMs] = useState([]);
  const [UMObject, setUMObject] = useState({});
  const [pages, setPages] = useState([]);
  const [active, setActive] = useState(1);
  const cardsPerPage = 4;
  const [formError, setFormError] = useState('');
  const [product, setProduct] = useState(
    {
      name: '',
      shortDescription: '',
      longDescription: '',
      image: '',
      idSupplier: '',
      UM: '',
      idFinalUnit: '',
      isPriceRecalculated: '',
      unitRelation: '',
      brutePrice: '',
      price: '',
      discount: '',
      cost: '',
      seasonStartDay: '',
      seasonStartMonth: '',
      seasonEndDay: '',
      seasonEndMonth: ''
    }
  );
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ]

  const module = {
      toolbar: toolbarOptions,
  };

  useEffect(() => {
    fetch(`${Environment()}/images/getPages/false`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      if(data[0].results > 0)
        setPages(Math.ceil(data[0].results / cardsPerPage)); 
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/images/getImages/${cardsPerPage}/${active}/false`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setImages(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [cardsPerPage, active])

  
  const addImage = (newItem) => {
    const itemExists = productImages.some(item => item.idImage === newItem.idImage);
    
    if (!itemExists && ((productImages[0] && productImages[0].isVideo === 0) || newItem.isVideo === 0)) {
      setProductImages(prevItems => [...prevItems, newItem]);
    }
  };
  
  const removeImage = (idImage) => {
    setProductImages(prevItems => prevItems.filter(item => item.idImage !== idImage));
  };

  function convertVideoURL(string){
    if(string){
      let video = '';
      video = string.split('/');
        if(video.length === 4){
            video = video[3].split('?')[0]
        } else {
            video  = '';
        }
  
      return video;
    }
  }

  const imageRows = Array.from(images).map((image, idx) => (
    <Col key={idx}>
      <Card>
        <Card.Header>
          <Row>
            <Button 
                className='PrimaryBtn'
                data-cy={idx === 1 ? "image category add" : ''}
                onClick={() => addImage({ idImage: image.idImage, imgExtension: image.imgExtension, video: image.video, isVideo: image.isVideo })}
                >
                <ion-icon 
                name="add" 
                />
              </Button>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Img hidden={image.isVideo} className='mb-5' src={`${Environment()}/image${image.idImage}${image.imgExtension}`}/>
          <iframe hidden={!image.isVideo} crossOrigin="anonymous" className='mb-5 card-img-top' src={`https://www.youtube-nocookie.com/embed/${convertVideoURL(image.video)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                
          <Card.Text className='text-center'><b><small>{image.name}</small></b></Card.Text>
          <Card.Text className='text-center'><i><small>{image.scientificName}</small></i></Card.Text>
        </Card.Body>
      </Card>
    </Col>
  ));
  
  //Disable scroll on number inputs
  useEffect(() => {
    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
          document.activeElement.blur();
        }
      });
    }, [])
    
    const arrayToObject = (array, keyField) =>
      array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
      }, {});
      
  useEffect(() => {
    if(props.id !== null){
      fetch(`${Environment()}/products/getProductAndCategoryById/${props.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {

        if(data.product && data.categories){
          setProduct(data.product);
          setSelectedCategories(data.categories.map(category => category.idCategory));
        }

        if(data.product.seasonStart && data.product.seasonEnd){
          const seasonStartDay = data.product.seasonStart.split(",")[0]
          const seasonStartMonth = data.product.seasonStart.split(",")[1]
          const seasonEndDay = data.product.seasonEnd.split(",")[0]
          const seasonEndMonth = data.product.seasonEnd.split(",")[1]
          
          updateForm({
            seasonStartDay:seasonStartDay,
            seasonStartMonth: seasonStartMonth,
            seasonEndDay: seasonEndDay,
            seasonEndMonth: seasonEndMonth,
          });
        }

        if(data.product.price){
          updateForm({brutePrice: (parseFloat(data.product.price) + parseFloat(data.product.discount)).toFixed(2)})
        }
      })
      .catch(err => {
        console.error(err);
      });
    }

    fetch(`${Environment()}/products/getProductImages/${props.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setProductImages(data);
    })
    .catch(err => {
        console.error(err);
    });
    
  }, [props.id])

  useEffect(() => {
    fetch(`${Environment()}/categories/getCategories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
        setCategories(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/suppliers/getSuppliers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setSuppliers(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setUMs(data);
      setUMObject(arrayToObject(data, 'idUnit'));
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setProduct((prev) => ({ ...prev, ...value }));
    }

   /**
   * onSubmit
   * @description Posts category through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(productImages.length < 1){
        setFormError("Adjunta por lo menos una imagen.")
        return;
      }

      fetch(`${Environment()}/products/getProduct/${product.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length && parseInt(data[0].idProduct) !== parseInt(props.id)){
          setFormError("El nombre del producto ya existe");
          return;
        }
        else{
          fetch(`${Environment()}/products/modifyProduct`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...product,
              seasonStart: (product.seasonStartDay && product.seasonStartMonth) ? (product.seasonStartDay + "," + product.seasonStartMonth) : null,
              seasonEnd: (product.seasonEndDay && product.seasonEndMonth) ? (product.seasonEndDay + "," + product.seasonEndMonth) : null,
              images: productImages,
              categories: selectedCategories
            })
          })
          .then(() => {
            setProduct(
              {
                name: '',
                shortDescription: '',
                longDescription: '',
                image: '',
                idCategory: '',
                idSupplier: '',
                UM: '',
                idFinalUnit: '',
                isPriceRecalculated: '',
                unitRelation: '',
                brutePrice: '',
                price: '',
                discount: '',
                cost: '',
                seasonEndDay: '',
                seasonEndMonth: ''
              }
            );
            
            window.location.reload();
          })
          .catch(err => {
            console.log(err); 
            setFormError('Hubo un error al modificar el producto. Inténtalo de nuevo más tarde.')
          });
        }
      })
      .catch(err => {
        console.log(err); 
        setFormError('Hubo un error al modificar el producto. Inténtalo de nuevo más tarde.')
      });
  }

  const months = [
    {number:1, name:'enero'}, 
    {number:2, name:'febrero'},
    {number:3, name:'marzo'},
    {number:4, name:'abril'},
    {number:5, name:'mayo'},
    {number:6, name:'junio'},
    {number:7, name:'julio'},
    {number:8, name:'agosto'},
    {number:9, name:'septiembre'},
    {number:10, name:'octubre'},
    {number:11, name:'noviembre'},
    {number:12, name:'diciembre'}
  ];

  const selectMonths = Array.from(months).map((month, idx) => (
  <option key={month.number} value={month.number}>{month.name}</option>
  ));

  
  useEffect(() => {
    function handleCategories(e, idCategory){
      const value = e.target.checked;
      let currentVals = [...selectedCategories];
      const index = currentVals.indexOf(idCategory);
  
      if(value){
        currentVals.push(idCategory);
      } else if (index > -1){
        currentVals = currentVals.filter(cat => cat !== idCategory);
      }
      setSelectedCategories(currentVals);
    }
    setCategorySelect(Array.from(categories).map((category, idx) => (
      <Form.Check
        onChange={(e) => handleCategories(e, category.idCategory)}
        key={category.idCategory}
        value={category.idCategory}
        type='checkbox'
        id={category.idCategory}
        label={category.name}
        checked={selectedCategories.indexOf(Number(category.idCategory)) !== -1}
      />                   
    )))
  }, [selectedCategories, categories])

  const supplierSelect = Array.from(suppliers).map((supplier, idx) => (
    <option key={supplier.idSupplier} value={supplier.idSupplier}>{supplier.name}</option>                    
  ));

  const UMSelect = Array.from(UMs).map((UM, idx) => (
    <option key={UM.idUnit} value={UM.idUnit}>{UM.singularName}</option>                    
  ));

  const addedImagesRows = Array.from(productImages).map((image, idx) => (
    <Col key={idx}>
      <Card>
        <Card.Header>
          <Row>
            <Button 
                variant='grey'
                onClick={() => removeImage(image.idImage)}
              >
                <ion-icon
                name="trash"
                />
              </Button>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Img hidden={image.isVideo} className='mb-5' src={`${Environment()}/image${image.idImage}${image.imgExtension}`}/>
          <iframe hidden={!image.isVideo} crossOrigin="anonymous" className='mb-5 card-img-top' src={`https://www.youtube-nocookie.com/embed/${convertVideoURL(image.video)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>     
        </Card.Body>
      </Card>
    </Col>
  ));

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>{props.title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductName"><b>Nombre *</b></Form.Label>
              <Form.Control
                required
                type="text"
                data-cy="product name"
                id="inputProductName"
                aria-describedby="productName"
                placeholder="Manzana roja"
                onChange={(e) => updateForm({ name: e.target.value })}
                value={product.name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductDesc"><b>Descripción corta *</b></Form.Label>
              <Form.Control
                required
                type="text"
                data-cy="product description"
                id="inputProductDesc"
                aria-describedby="productDescription"
                placeholder="Manzana Royal Gala"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                value={product.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductLongDesc"><b>Descripción larga</b></Form.Label>
                <ReactQuill data-cy="product long description" maxLength={16777210} required modules={module} theme='snow' value={product.longDescription} onChange={(content) => updateForm({ longDescription: content })}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Categorías *</b></Form.Label>
                {categorySelect}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductSupp"><b>Proveedor *</b></Form.Label>
              <Form.Select
                required
                id="inputProductSuppt"
                data-cy="product supplier"
                aria-describedby="productSupplier"
                onChange={(e) => updateForm({ idSupplier: e.target.value })}
                value={product.idSupplier}
              >
                <option value=''>Selecciona un proveedor</option>  
                {supplierSelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductDesc"><b>Unidad de medida pedido *</b></Form.Label>
              <Form.Select
                required
                type="text"
                id="inputProductUM"
                data-cy="product UM"
                aria-describedby="productUM"
                placeholder="KG"
                onChange={(e) => updateForm({ idUnit: e.target.value })}
                value={product.idUnit}
              >
                <option value=''>Selecciona una unidad de medida</option>
                {UMSelect} 
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductDesc"><b>Unidad de medida venta *</b></Form.Label>
              <Form.Select
                required
                type="text"
                id="inputProductUM"
                data-cy="product final unit"
                aria-describedby="productUM"
                placeholder="KG"
                onChange={(e) => updateForm({ idFinalUnit: e.target.value })}
                value={product.idFinalUnit}
              >
                <option value=''>Selecciona una unidad de medida</option>
                {UMSelect} 
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>¿El producto se tiene que pesar o medir antes de la entrega? *</b></Form.Label>
              <Form.Select
                required
                type="text"
                data-cy="product isPriceRecalculated"
                placeholder="KG"
                onChange={(e) => {updateForm({ isPriceRecalculated : e.target.value }); updateForm({ unitRelation: e.target.value === 0 ? 1 : product.unitRelation })}}
                value={product.isPriceRecalculated}
              >
                <option value=''>Selecciona una opción</option>
                <option value={1}>Si</option>
                <option value={0}>No</option>
              </Form.Select>
            </Form.Group>
            <Form.Label className='green'><b>Escribe la relación que tienen apróximadamente ambas unidades de medida *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>1 {UMObject[product.idUnit] ? UMObject[product.idUnit].singularName : '' } es igual a </InputGroup.Text>
              <Form.Control
                required
                data-cy="product UM relation"
                disabled={product.isPriceRecalculated === 0}
                type="number"
                placeholder="30.99"
                step={ UMObject[product.idFinalUnit] ? UMObject[product.idFinalUnit].isFloat === 1 ? '0.001' : '1' : ''}
                min={UMObject[product.idFinalUnit] ? UMObject[product.idFinalUnit].isFloat === 1 ? '0.001' : '1' : ''}
                onChange={(e) => {updateForm({ unitRelation: e.target.value})}}
                value={product.unitRelation}
              />
              <InputGroup.Text>{UMObject[product.idFinalUnit]? UMObject[product.idFinalUnit].singularName : ''}</InputGroup.Text>
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductCost"><b>Costo (costo de producción o pago al productor) *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                data-cy="product cost"
                id="inputProductCost"
                aria-describedby="productCost"
                placeholder="12"
                min={0}
                onChange={(e) => updateForm({ cost: e.target.value })}
                value={product.cost}
              />
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductPrice"><b>Precio (precio a los clientes de Terra Viva) *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                data-cy="product price"
                id="inputProductPrice"
                aria-describedby="productPrice"
                placeholder="30.99"
                min={0}
                onChange={(e) => {updateForm({ brutePrice: e.target.value}); updateForm({ price: e.target.value - product.discount});}}
                value={product.brutePrice}
              />
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductDiscount"><b>Descuento *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                data-cy="product discount"
                id="inputProductDiscount"
                aria-describedby="productDiscount"
                placeholder="0.99"
                min={0}
                max={product.brutePrice}
                onChange={(e) => { updateForm({ discount: e.target.value }); updateForm({ price: product.brutePrice - e.target.value});}}
                value={product.discount}
              />
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductDiscount"><b>Precio con descuento</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                disabled
                type="number"
                id="inputProductPriceDiscount"
                aria-describedby="productPrice"
                placeholder="30"
                min={0}
                value={product.price}
              />
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductDiscount"><b>Inicio de temporada</b></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                data-cy="product seasonStartDay"
                value={product.seasonStartDay}
                min={1}
                max={31}
                onChange={(e) => updateForm({ seasonStartDay: e.target.value })}
                placeholder='Escribe un día'
                >
              </Form.Control>
              <Form.Select
                type="text"
                data-cy="product seasonStartMonth"
                value={product.seasonStartMonth}
                onChange={(e) => updateForm({ seasonStartMonth: e.target.value })}
                >
                <option value=''>Selecciona un mes</option>
                {selectMonths}
              </Form.Select>
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductDiscount"><b>Fin de temporada</b></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                data-cy="product seasonEndDay"
                value={product.seasonEndDay}
                min={1}
                max={31}
                placeholder='Escribe un día'
                onChange={(e) => updateForm({ seasonEndDay: e.target.value })}
              >
              </Form.Control>
              <Form.Select
                type="text"
                data-cy="product seasonEndMonth"
                value={product.seasonEndMonth}
                onChange={(e) => updateForm({ seasonEndMonth: e.target.value })}
              >
                <option value=''>Selecciona un mes</option>
                {selectMonths}
              </Form.Select>
            </InputGroup>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Imágenes *</b></Form.Label>
              <Container className='mt-3'>
              <Row md={4} lg={4} xl={4}>
                {addedImagesRows}
              </Row>
              </Container>
              <Container className='mt-3'>
                <Row>
                  <Col>
                    <ion-icon onClick={ e => {setActive(active <= 1 ? 1 : active - 1)}} name="arrow-back-outline"></ion-icon>
                  </Col>
                  <Col className='text-center'>
                    <p><b>Galería</b></p>
                  </Col>
                  <Col className='text-end'>
                    <ion-icon onClick={ e => {setActive(active >= pages ? pages : active + 1)}} name="arrow-forward-outline"></ion-icon>
                  </Col>
                </Row>
                <Row md={4} lg={4} xl={4}>
                  {imageRows}
                </Row>
              </Container>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button data-cy="submit product" type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModifyProductModal;