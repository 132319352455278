import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logoTerraViva from './../images/logoTerraViva.png';
import './components.css';

function FooterHome() {
    return (
            <Navbar className='home-blue-bg'>
                <Container fluid className='containerNav'> 
                    <Navbar.Brand className='ps-2' href="/inicio"><Image  width="70" src={logoTerraViva}/></Navbar.Brand>
                    <Nav
                        style={{ maxHeight: '100px' }}
                        className="mx-auto text-center"
                    >
                        <Nav.Item className='d-none d-lg-block'>
                            <Nav.Link className="antiquewhite-text">
                                    <small>Encuéntranos en:</small>
                            </Nav.Link>
                        </Nav.Item> 
                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/facebook" href="https://www.facebook.com/Terra.Viva.Qro" target="_blank" rel="noopener noreferrer">
                                    <ion-icon slot="start" name="logo-facebook"/><br />
                            </Nav.Link>
                        </Nav.Item> 

                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/instagram" href="/" target="_blank" rel="noopener noreferrer">
                                    <ion-icon slot="start" name="logo-instagram"/><br />
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/tiktok" href="https://www.tiktok.com/@terravivaqro" target="_blank" rel="noopener noreferrer">
                                    <ion-icon slot="start" name="logo-tiktok"/><br />
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/whatsapp" href="https://wa.me/+524421111114" target="_blank" rel="noopener noreferrer">
                                    <ion-icon slot="start" name="logo-whatsapp"/><br />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/phone" href="tel:+524421111114" target="_blank" rel="noopener noreferrer">
                                    <ion-icon slot="start" name="call-outline"/><br />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Nav
                        style={{ maxHeight: '100px' }}
                        className="mx-auto text-center"
                    >
                        <Nav.Item>
                            <Nav.Link className="antiquewhite-text" id="/aviso-privacidad" href="/aviso-privacidad">
                                    <small>Aviso de privacidad</small>
                            </Nav.Link>
                        </Nav.Item> 
                    </Nav>                           
                </Container>
            </Navbar>
    );
}

export default FooterHome;